<template>
    <div class="board-container">
        <v-simple-table class="table-board align-center">
            <thead>
                <tr>
                    <th style="width:7%" class="d-none d-lg-table-cell">번호</th>
                    <th style="width:66%" class="table-board__tit">제목</th>
                    <th style="width:10%">이름</th>
                    <th style="width:10%">작성일</th>
                    <th style="width:7%">조회</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notice, index) in notices" :key="`notice-${index}`" @click="showBoard(notice)" style="cursor:pointer; background-color:#fffcf6;">
                    <td class="d-none d-lg-table-cell">공지</td>
                    <td class="table-board__tit"><div class="ellipsis">{{ notice.subject }}</div></td>
                    <td>{{ notice.writer.name }}</td>
                    <td>{{ notice.createdAt.toDate() }}</td>
                    <td>{{ notice.viewCount }}</td>
                </tr>
                <tr v-for="(board, index) in boards" :key="`board-${index}`" @click="showBoard(board)" style="cursor:pointer;">
                    <td class="d-none d-lg-table-cell">{{ summary.totalCount - skip - index }}</td>
                    <td class="table-board__tit"><div class="ellipsis">{{ board.subject }}</div></td>
                    <td>{{ board.writer.name }}</td>
                    <td>{{ board.createdAt.toDate() }}</td>
                    <td>{{ board.viewCount }}</td>
                </tr>
            </tbody>
        </v-simple-table>
        <div class="board-buttons board-buttons--center">
            <button @click="$router.push(`${$route.path}/write`)" class="button button--primary">글쓰기</button>
        </div>
        <div class="board-bottom">
            <pagination-component :count="count" :value="page" @change="changePage" />
        </div>
        <!-- <div class="board-buttons board-buttons--center">
            <a v-if="boards.length < summary.totalCount" class="button button--border" role="button" @click="$emit('loadmore')"><span>더보기</span></a>
            <a class="button button--primary" role="link" @click="$router.push('?mode=input')"><span>글쓰기</span></a>
        </div> -->
    </div>
</template>

<script>
import paginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: { paginationComponent },

    props: {
        code: { type: String, default: null },
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        notices: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
        showBoard(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>
